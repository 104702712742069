import React from 'react';

const FiltersComponent = ({
    showDateRange = true,
    showGroupOption = true,
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    groupOption,
    handleGroupChange,
    filterOptions,
    currentFilter,
    handleFilterChange,
    handleAddFilter,
    filters,
    handleRemoveFilter,

    // --- Ajout des nouvelles props :
    showToggleCapacity = false, // pour contrôler l'affichage de la zone
    showCapacity,               // booléen qui indique si la case à cocher est activée
    onShowCapacityChange,       // callback quand l’utilisateur clique sur la checkbox
}) => {
    return (
        <div className="filters-container">
            <div className="card-full">
                {showGroupOption && (
                    <>
                        <h5>Display as</h5>
                        <div className="display-line dropdown">
                            <select value={groupOption} onChange={handleGroupChange}>
                                <option value="month">Monthly view</option>
                                <option value="quarter">Quarterly view</option>
                            </select>
                        </div>
                        <div className='perf-form-separator'></div>
                    </>
                )}

                <h5>Dates</h5>
                <div className="display-line">
                    <div className="chart-lines">
                        {showDateRange ? (
                            <>
                                <span>From </span>
                                <input
                                    type="date"
                                    className="chart-dates"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                />
                                <span>to</span>
                                <input
                                    type="date"
                                    className="chart-dates"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                />
                            </>
                        ) : (
                            <input
                                type="date"
                                className="chart-dates"
                                value={startDate}
                                onChange={handleStartDateChange}
                            />
                        )}
                    </div>
                </div>
                <div className='perf-form-separator'></div>

                <div className="line-container-10 dropdown">
                    <h5>Filter by</h5>
                    <select
                        className="dropdown"
                        name="column"
                        onChange={handleFilterChange}
                        value={currentFilter.column}
                    >
                        <option value="">Filter by</option>
                        <option value="location">Location</option>
                        <option value="team">Team</option>
                    </select>
                    <select
                        className="dropdown"
                        name="value"
                        onChange={handleFilterChange}
                        value={currentFilter.value}
                        disabled={!currentFilter.column}
                    >
                        <option value="">Select Value</option>
                        {currentFilter.column === 'location' &&
                            filterOptions.locations
                                .filter(location => location !== null) // Filtre pour éviter les `null`
                                .map((location, index) => (
                                    <option key={index} value={location}>
                                        {location}
                                    </option>
                                ))}
                        {currentFilter.column === 'team' &&
                            filterOptions.teams.map((team, index) => (
                                <option key={index} value={team.id}>
                                    {team.nom_equipe}
                                </option>
                            ))}
                    </select>
                    <button
                        type="button"
                        onClick={handleAddFilter}
                        className="btn-secondary"
                        disabled={!currentFilter.column || !currentFilter.value}
                    >
                        Add Filter
                    </button>

                    {filters.map((filter, index) => (
                        <div key={index} className="filter-tag">
                            {filter.column}: {filter.displayValue}
                            <button
                                className="filter-tag-btn"
                                type="button"
                                onClick={() => handleRemoveFilter(index)}
                            >
                                x
                            </button>
                        </div>
                    ))}
                </div>
                <div className='perf-form-separator'></div>
                {/* Rendu conditionnel de la zone de capacité */}
                {showToggleCapacity && (
                    <div className="line-container-10">
                        <h5>Capacity</h5>
                        <label style={{ cursor: 'pointer' }}>
                            <input
                                type="checkbox"
                                checked={showCapacity}
                                onChange={onShowCapacityChange}
                                style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                            />
                            Afficher la liste des localisations (et la capacité)
                        </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FiltersComponent;
