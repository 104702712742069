import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import amplitude from 'amplitude-js';

const DataHealth = () => {
  useEffect(() => {
    // Initialiser Amplitude avec votre clé API





    // Suivre un événement lorsque le composant est monté
    amplitude.getInstance().logEvent('DataHealth Page Viewed');

    // Rediriger vers YouTube après 800 ms
    const timer = setTimeout(() => {
      window.location.href = 'https://www.youtube.com/watch?v=YdFdX4oVXu4';
    }, 400);

    // Nettoyer le timer si le composant est démonté avant la fin du délai
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="loader-container">
      <FontAwesomeIcon icon={faSpinner} spin />
      <p>Loading...</p>
    </div>
  );
};

export default DataHealth;
