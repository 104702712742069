import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import "../../styles/Text.css";
import "../../styles/Container.css";
import "../../styles/Home.css";
import "../../styles/Agents.css";
import insight from "../../assets/insight.webp";
import pythagore from "../../assets/pythagore.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import AgentUpload from '../../components/form/AgentUpload';

const Agents = () => {
  const [pythagoreTasks, setPythagoreTasks] = useState([]);
  const [insightsTasks, setInsightsTasks] = useState([]);
  const [loadingPythagore, setLoadingPythagore] = useState(true);
  const [loadingInsights, setLoadingInsights] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const navigate = useNavigate();
  const chatEndRef = useRef(null);

  const simulateDataFetch = () => {
    const mockPythagoreTasks = [
      { task: "Vérification de votre SIRH Lucca", status: "Aucune modification récente" },
      { task: "WARNING : Erreur sur les données d’embauche", status: "En attente de correction" },
      { task: "Rapport de cohérence des champs généré", status: "OK" },
    ];

    const mockInsightsTasks = [
      { task: "Analyse des tendances de turnover", status: "Terminé" },
      { task: "Prévision des besoins de recrutement", status: "En cours" },
      { task: "Rapport sur la diversité généré", status: "OK" },
    ];

    setTimeout(() => {
      setPythagoreTasks(mockPythagoreTasks);
      setLoadingPythagore(false);

      setInsightsTasks(mockInsightsTasks);
      setLoadingInsights(false);
    }, 1000); // Simuler un délai de 1 seconde
  };

  const addBotMessage = (message, delay = 500) => {
    return new Promise((resolve) => {
      setIsTyping(true);
      setTimeout(() => {
        setChatHistory((prevHistory) => [
          ...prevHistory,
          { sender: 'bot', message },
        ]);
        setIsTyping(false);
        resolve();
      }, delay);
    });
  };

  const sendMessage = async () => {
    if (!userMessage.trim()) return;

    const userChat = { sender: 'user', message: userMessage };
    setChatHistory((prevHistory) => [...prevHistory, userChat]);
    setUserMessage('');

    const botResponse = "Merci pour votre message. Je suis Polarus, votre assistant IA, ici pour vous aider avec la planification des effectifs.";
    await addBotMessage(botResponse);
  };

  useEffect(() => {
    simulateDataFetch();
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  return (
    <div className='container-home'>
      <Sidebar />
      <div className="container-right">
        <Header />
        <AgentUpload/>
        <div className='line-container-10'>
          <h5>Agents IA Polare - Recent Tasks</h5>
        </div>

        <div className='display-line'>
          {/* Pythagore Tasks */}
          <div className='card-half-full'>
            <div className="section-header">
              <div className='section-icon'>
                <img src={pythagore} alt="Pythagore Icon" className="header-icon" />
                <p className='section-icon-text'>Pythagore</p>
              </div>

            </div>
            {loadingPythagore ? (
              <div className="loader-icon"><FontAwesomeIcon icon={faSpinner} spin /></div>
            ) : pythagoreTasks.length > 0 ? (
              <>
                <table className="positions-table">
                  <thead>
                    <tr>
                      <th>Task</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pythagoreTasks.map((task, index) => (
                      <tr key={index}>
                        <td>{task.task}</td>
                        <td>{task.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button className='btn-secondary' onClick={() => navigate('/pythagore')}>
                  View all tasks
                </button>
              </>
            ) : (
              <p>No recent tasks.</p>
            )}
          </div>

          {/* Insights Tasks */}
          <div className='card-half-full'>
            <div className="section-header">
              <div className='section-icon'>
                <img src={insight} alt="Insights Icon" className="header-icon" />
                <p className='section-icon-text'>Insights</p>
              </div>
              
            </div>
            {loadingInsights ? (
              <div className="loader-icon"><FontAwesomeIcon icon={faSpinner} spin /></div>
            ) : insightsTasks.length > 0 ? (
              <>
                <table className="positions-table">
                  <thead>
                    <tr>
                      <th>Task</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insightsTasks.map((task, index) => (
                      <tr key={index}>
                        <td>{task.task}</td>
                        <td>{task.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button className='btn-secondary' onClick={() => navigate('/insights')}>
                  View all tasks
                </button>
              </>
            ) : (
              <p>No recent tasks.</p>
            )}
          </div>
        </div>
        <div className='line-container-10'>
          <h5>To check your datas</h5>
        </div>
        {/* Chat Component */}
        <div className="agent-chat-bot-container">
          <div className="agent-chat-history">

            {chatHistory.map((chat, index) => (
              <div
                key={index}
                className={chat.sender === 'user' ? 'chat-user' : 'chat-bot'}
              >
                {chat.message}
              </div>
            ))}
            {isTyping && (
              <div className="agent-chat-bot typing-animation">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
            <div ref={chatEndRef} />
          </div>
          <div className="agent-chat-input">
            <input
              type="text"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Ask Polarus to help you..."
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendMessage();
                }
              }}
            />
            <button type= "submit" onClick={sendMessage}>Send</button>
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default Agents;
