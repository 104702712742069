import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import amplitude from 'amplitude-js';

const AgentUpload = () => {
  const { id } = useParams(); // ID récupéré depuis l'URL (si besoin).
  const [csvFile, setCsvFile] = useState(null);
  const [transformedCsv, setTransformedCsv] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // État pour le loader
  const storedUserData = localStorage.getItem('userData');
  const ampData = storedUserData ? JSON.parse(storedUserData) : {};
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  // Fonction pour gérer le drag & drop
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setCsvFile(event.dataTransfer.files[0]);
    }
  };

  // Gestion de la sélection de fichier via input
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCsvFile(event.target.files[0]);
    }
  };

  // Fonction pour envoyer le fichier CSV au backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setTransformedCsv('');
    setIsLoading(true); // Activer le loader

    if (!csvFile) {
      setErrorMessage('Veuillez sélectionner ou déposer un fichier CSV.');
      setIsLoading(false); // Désactiver le loader en cas d'erreur
      return;
    }

    try {
      amplitude.getInstance().logEvent('AgentCsvForm_UploadAttempt', {
        team_id: id || 'unknown',
        username: ampData.username || 'unknown',
      });

      const formData = new FormData();
      formData.append('csv_file', csvFile);

      const response = await fetch(`${apiUrl}/chatbot/transform_csv/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (!response.ok || result.errors) {
        setErrorMessage(`Erreur réseau ou serveur : ${result.errors || 'Unknown error'}`);
        amplitude.getInstance().logEvent('AgentCsvForm_UploadError', {
          error: result.errors || 'Unknown error',
          team_id: id || 'unknown',
          username: ampData.username || 'unknown',
        });
        setIsLoading(false); // Désactiver le loader en cas d'erreur
        return;
      }

      setTransformedCsv(result.transformed_csv);

      amplitude.getInstance().logEvent('AgentCsvForm_UploadSuccess', {
        team_id: id || 'unknown',
        username: ampData.username || 'unknown',
      });
    } catch (error) {
      setErrorMessage(error.message || 'Une erreur est survenue.');
      amplitude.getInstance().logEvent('AgentCsvForm_UploadError', {
        error: error.message,
        team_id: id || 'unknown',
        username: ampData.username || 'unknown',
      });
    } finally {
      setIsLoading(false); // Désactiver le loader après la requête
    }
  };

  // Fonction pour transformer le CSV brut en tableau
  const parseCsv = (csv) => {
    const [headerLine, ...lines] = csv.split("\n").filter(line => line.trim() !== "");
    const headers = headerLine.split(",");

    return lines.map(line => {
      const values = line.split(",");
      return headers.reduce((acc, header, index) => {
        acc[header] = values[index] || "";
        return acc;
      }, {});
    });
  };

  // Fonction pour afficher les données sous forme de tableau HTML
  const renderTable = (data) => {
    if (!data || data.length === 0) {
      return <p>Aucune donnée à afficher.</p>;
    }

    const headers = Object.keys(data[0]);

    return (
      <table style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px" }}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th
                key={header}
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header) => (
                <td
                  key={header}
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                  }}
                >
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="card-half-full">
      <h5>Turing</h5>

      {/* Zone de drag & drop */}
      <div
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
          border: '2px dashed #ccc',
          borderRadius: '5px',
          padding: '20px',
          marginBottom: '20px',
          textAlign: 'center'
        }}
      >
        {csvFile ? (
          <p>{csvFile.name}</p>
        ) : (
          <p>Glissez-déposez un fichier CSV ici, ou cliquez pour sélectionner un fichier.</p>
        )}
      </div>

      {/* Champ input file */}
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        style={{ marginBottom: '20px' }}
      />

      {/* Bouton pour envoyer le CSV */}
      <button type="submit" onClick={handleSubmit} disabled={isLoading}>
        {isLoading ? "Chargement..." : "Envoyer le CSV pour transformation"}
      </button>

      {/* Affichage des erreurs */}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      {/* Loader */}
      {isLoading && <p>Chargement des données, veuillez patienter...</p>}

      {/* Affichage des données transformées */}
      {transformedCsv && !isLoading && (
        <div>
          <h5>Résultat de la transformation :</h5>
          {renderTable(parseCsv(transformedCsv))}
        </div>
      )}
    </div>
  );
};

export default AgentUpload;
