import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import FiltersComponent from '../../../components/recrutement/chartv2/FiltersComponent';
import ChartWithKPIsComponent from '../../../components/recrutement/chartv2/ChartWithKPIsComponent';
import TableComponent from '../../../components/recrutement/chartv2/TableComponent';
import { faClipboard, faPeopleGroup, faPencil } from '@fortawesome/free-solid-svg-icons';

const InsightPagePopulationLocation = () => {
    const [populationData, setPopulationData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [filterOptions, setFilterOptions] = useState({ teams: [], locations: [] });
    const [filters, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '', displayValue: '' });
    const [chartData, setChartData] = useState(null);
    const [kpiDataArray, setKpiDataArray] = useState([]);
    const [filteredPopulationData, setFilteredPopulationData] = useState([]);
    const [filteredHistoricalData, setFilteredHistoricalData] = useState([]);
    const [historiqueTableData, setHistoriqueTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // -- Date par défaut = aujourd'hui
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);

    // -- État pour afficher la "capacité" (et donc la courbe + la table)
    const [showCapacity, setShowCapacity] = useState(false);

    // -- Liste complète des localisations (pour la capacité)
    const [localisations, setLocalisations] = useState([]);

    const navigate = useNavigate();

    // =======================
    // 1. Requête GraphQL
    // =======================
    const fetchGraphQLData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData?.entreprise_id;

            if (!entrepriseId) {
                setError('Entreprise ID non trouvé dans les données utilisateur.');
                return;
            }

            const query = `
                query ($dateToCheck: Date!, $entrepriseId: Int!) {
                    populationLocationByPeriod(entrepriseId: $entrepriseId, dateToCheck: $dateToCheck) {
                        location
                        teams {
                            name
                            population
                            openPositionsSigned
                            openPositionsForecasted
                        }
                    }
                    historiquesEmploi(dateToCheck: $dateToCheck, entrepriseId: $entrepriseId) {
                        employe {
                            firstName
                            lastName
                        }
                        equipe {
                            nomEquipe
                        }
                        metier {
                            titreMetier
                        }
                        localisation
                        dateDebut
                    }
                    equipes(entrepriseId: $entrepriseId) {
                        id
                        nomEquipe
                    }
                    localisations(entrepriseId: $entrepriseId) {
                        id
                        nomLocal
                        capacite
                    }
                }
            `;

            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    query,
                    variables: { dateToCheck: startDate, entrepriseId },
                }),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données.');
            }

            const { data } = await response.json();
            if (data) {
                setPopulationData(data.populationLocationByPeriod || []);
                setHistoricalData(data.historiquesEmploi || []);

                setFilterOptions({
                    teams: data.equipes.map((team) => ({
                        nom_equipe: team.nomEquipe,
                        id: team.id,
                    })),
                    locations: [
                        ...new Set(data.populationLocationByPeriod.map((item) => item.location)),
                    ].filter(Boolean),
                });

                // On stocke la liste complète des localisations
                setLocalisations(data.localisations || []);
            }
        } catch (err) {
            setError('Erreur lors de la récupération des données.');
        } finally {
            setLoading(false);
        }
    };

    // =======================
    // 2. Gestion des filtres
    // =======================
    const applyFilters = (data) => {
        let filteredData = data;

        filters.forEach((filter) => {
            if (!filter.value || filter.value === 'All') return;

            if (filter.column === 'team') {
                filteredData = filteredData
                    .map((item) => {
                        if (!Array.isArray(item.teams)) return item;
                        const filteredTeams = item.teams.filter(
                            (team) => team.name === filter.displayValue
                        );
                        return { ...item, teams: filteredTeams };
                    })
                    .filter((item) => Array.isArray(item.teams) && item.teams.length > 0);
            } else if (filter.column === 'location') {
                filteredData = filteredData.filter(
                    (item) => item.location === filter.value
                );
            }
        });

        return filteredData;
    };

    const applyFiltersToEmployees = (data) => {
        let filteredData = data;

        filters.forEach((filter) => {
            if (!filter.value || filter.value === 'All') return;
            if (filter.column === 'team') {
                filteredData = filteredData.filter(
                    (item) => item.equipe?.nomEquipe === filter.displayValue
                );
            } else if (filter.column === 'location') {
                filteredData = filteredData.filter(
                    (item) => item.localisation === filter.value
                );
            }
        });

        return filteredData;
    };

    // ========================
    // 3. Préparation du Graph
    // ========================
    const aggregateChartDataByLocation = (data) => {
        if (!data.length) {
            setChartData({ labels: [], datasets: [] });
            return;
        }

        // On agrège la population par location
        const aggregated = data.map((item) => ({
            location: item.location || 'Localisation inconnue',
            population: item.teams.reduce((sum, team) => sum + (team.population || 0), 0),
            openPositionsSigned: item.teams.reduce((sum, team) => sum + (team.openPositionsSigned || 0), 0),
            openPositionsForecasted: item.teams.reduce((sum, team) => sum + (team.openPositionsForecasted || 0), 0),
        }));

        // Construction de la liste de datasets (barres stackées)
        const newDatasets = [
            {
                label: 'Current Workforce',
                data: aggregated.map((item) => item.population),
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
                type: 'bar',
                stack: 'stack1',
            },
            {
                label: 'Open Positions Signed',
                data: aggregated.map((item) => item.openPositionsSigned),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                type: 'bar',
                stack: 'stack1',
            },
            {
                label: 'Open Positions Forecasted',
                data: aggregated.map((item) => item.openPositionsForecasted),
                backgroundColor: 'rgba(71, 179, 156, 0.6)',
                type: 'bar',
                stack: 'stack1',
            },
        ];

        // ==> Si la checkbox est cochée (showCapacity), on ajoute un dataset supplémentaire pour la capacité
        if (showCapacity) {
            const capacityData = aggregated.map((item) => {
                const matchingLoc = localisations.find(
                    (loc) => loc.nomLocal === item.location
                );
                return matchingLoc ? matchingLoc.capacite : 0;
            });

            newDatasets.push({
                label: 'Capacity',
                data: capacityData,
                type: 'line',
                yAxisID: 'y',
                borderColor: 'rgba(255, 206, 86, 1)',
                backgroundColor: 'rgba(255, 206, 86, 0.2)',
                borderWidth: 2,
                fill: false,
                tension: 0.1,
            });
        }

        setChartData({
            labels: aggregated.map((item) => item.location),
            datasets: newDatasets,
        });

        // Mise à jour des KPIs
        setKpiDataArray([
            {
                title: 'Total Workforce',
                value: aggregated.reduce((sum, item) => sum + item.population, 0),
                icon: faPeopleGroup,
                color: 'rgba(54, 162, 235, 1)',
            },
            {
                title: 'Open Positions Signed',
                value: aggregated.reduce((sum, item) => sum + item.openPositionsSigned, 0),
                icon: faClipboard,
                color: 'rgba(255, 99, 132, 1)',
            },
            {
                title: 'Open Positions Forecasted',
                value: aggregated.reduce((sum, item) => sum + item.openPositionsForecasted, 0),
                icon: faPencil,
                color: 'rgba(71, 179, 156, 1)',
            },
        ]);
    };

    // ===================================
    // 4. useEffect pour récupérer les data
    // ===================================
    useEffect(() => {
        fetchGraphQLData();
        // eslint-disable-next-line
    }, [startDate]);

    // Filtrage et construction de chartData à chaque changement
    useEffect(() => {
        const filteredPopulation = applyFilters(populationData);
        setFilteredPopulationData(filteredPopulation);

        const filteredHist = applyFiltersToEmployees(historicalData);
        setFilteredHistoricalData(filteredHist);

        aggregateChartDataByLocation(filteredPopulation);
        // eslint-disable-next-line
    }, [populationData, historicalData, filters, showCapacity]);

    useEffect(() => {
        setHistoriqueTableData(
            filteredHistoricalData.map((item) => ({
                employe: `${item.employe?.firstName || 'Prénom inconnu'} ${item.employe?.lastName || ''}`,
                equipe: item.equipe?.nomEquipe || 'Équipe inconnue',
                metier: item.metier?.titreMetier || 'Métier inconnu',
                location: item.localisation || 'Localisation inconnue',
            }))
        );
    }, [filteredHistoricalData]);

    // ===================================
    // 5. Handlers pour les filtres
    // ===================================
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter((prev) => {
            if (name === 'column') {
                return { ...prev, column: value, value: '', displayValue: '' };
            } else if (name === 'value') {
                if (currentFilter.column === 'team') {
                    const selectedTeam = filterOptions.teams.find(
                        (team) => team.id.toString() === value
                    );
                    const displayValue = selectedTeam ? selectedTeam.nom_equipe : value;
                    return { ...prev, value, displayValue };
                } else if (currentFilter.column === 'location') {
                    return { ...prev, value, displayValue: value };
                }
            }
            return prev;
        });
    };

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            setFilters((prev) => [...prev, currentFilter]);
            setCurrentFilter({ column: '', value: '', displayValue: '' });
        }
    };

    const handleRemoveFilter = (index) => {
        setFilters((prev) => prev.filter((_, i) => i !== index));
    };

    // =======================
    // 6. Rendu final
    // =======================
    if (loading) return <p>Chargement des données...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div style={{ backgroundColor: colors.background }} className="container-home">
            <Sidebar isCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className="content-container">
                    <FiltersComponent
                        showDateRange={false}
                        showGroupOption={false}
                        filterOptions={filterOptions}
                        currentFilter={currentFilter}
                        handleFilterChange={handleFilterChange}
                        handleAddFilter={handleAddFilter}
                        filters={filters}
                        handleRemoveFilter={handleRemoveFilter}
                        startDate={startDate}
                        handleStartDateChange={(e) => setStartDate(e.target.value)}

                        // -------- Nouveau : on affiche la case à cocher (showToggleCapacity)
                        // et on lie l'état showCapacity
                        showToggleCapacity={true}
                        showCapacity={showCapacity}
                        onShowCapacityChange={(e) => setShowCapacity(e.target.checked)}
                    />

                    <div className='container-data'>
                        <ChartWithKPIsComponent
                            title="Workforce Overview by Location"
                            kpiDataArray={kpiDataArray}
                            chartType="bar"
                            showToggle={true}
                            chartOptions={{
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        stacked: true,
                                        title: { display: true, text: 'Location' },
                                    },
                                    y: {
                                        beginAtZero: true,
                                        stacked: true,
                                        title: {
                                            display: true,
                                            text: 'Staff Count & Signed Positions'
                                        },
                                    },
                                },
                            }}
                            chartData={chartData}
                            columnstable={[
                                { label: 'Employé', key: 'employe' },
                                { label: 'Équipe', key: 'equipe' },
                                { label: 'Location', key: 'location' },
                            ]}
                            titletable="Employés Overview by Location"
                            datatable={historiqueTableData}
                        />

                        <div className='card-full'>
                            <TableComponent
                                title="Population Data Table"
                                columns={[
                                    { label: 'Location', key: 'location' },
                                    { label: 'Workforce', key: 'population' },
                                    { label: 'Open Positions Signed', key: 'openPositionsSigned' },
                                    { label: 'Open Positions Forecasted', key: 'openPositionsForecasted' },
                                ]}
                                data={filteredPopulationData.map((item) => ({
                                    location: item.location || 'Localisation inconnue',
                                    population: item.teams.reduce((sum, team) => sum + (team.population || 0), 0),
                                    openPositionsSigned: item.teams.reduce((sum, team) => sum + (team.openPositionsSigned || 0), 0),
                                    openPositionsForecasted: item.teams.reduce((sum, team) => sum + (team.openPositionsForecasted || 0), 0),
                                }))}
                            />
                        </div>

                        {/* Affichage conditionnel de la liste des localisations si showCapacity = true */}
                        {showCapacity && (
                            <div className='card-full'>
                                <TableComponent
                                    title="Liste des localisations"
                                    columns={[
                                        { label: 'Nom', key: 'nomLocal' },
                                        { label: 'Capacité', key: 'capacite' },
                                    ]}
                                    data={localisations.map((loc) => ({
                                        nomLocal: loc.nomLocal,
                                        capacite: loc.capacite,
                                    }))}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsightPagePopulationLocation;
